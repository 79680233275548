import React, { useState, useCallback, useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessIcon from '@material-ui/icons/ExpandLessRounded';
import cx from 'class-names';
import {
  Button,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Input,
  FormControl,
  RadioGroup,
  Radio,
  Paper,
  Typography,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Question } from './components/Question';
import { Answer } from './components/Answer';
import { BondEvalStrategy } from '../_libs/strategies/BondEvalStrategy';
import { EvalStrategyManager } from '../_libs/strategies/EvalStrategyManager';
import { GeneralStrategy } from '../_libs/strategies/GeneralStrategy';
import { ReactComponent as Logo } from '../_assets/images/logo-new.svg';
import texture from '../_assets/images/texture.png';
import { showErrorSnackbar, showSnackbar } from '../_libs/snackbar';
import { BillOfExchangeEvalStrategy } from '../_libs/strategies/BillOfExchangeEvalStrategy';
import { ShareEvalStrategy } from '../_libs/strategies/ShareEvalStrategy';
import { QUESTION_RESULT } from '../_constants/commonConstants';
import ExportImportPopover from './SimplePopover';
import { formatDateForExport } from '../_libs/utils';
import { PriorityShareEvalStrategy } from '../_libs/strategies/PriorityShareEvalStrategy';

const getQuestionsAndAnswersInitState = () => {
  const arr = new Array(31).fill(null).map(() => []);

  // Sets arrays for table questions
  arr[15][1] = [];
  arr[15][2] = [];
  arr[15][3] = [];

  arr[16][1] = [];
  arr[16][2] = [];
  arr[16][3] = [];

  return arr;
};

const initialState = {
  birthYear: '',
  age: 0,
  investment: '',
  investmentPercentageFromAssets: '',
  questionsAndAnswers: getQuestionsAndAnswersInitState()
};

const initialEvalState = {
  general: [],
  bonds: [],
  billsOfEchange: [],
  shares: [],
  priorityShares: []
};

const useStyles = makeStyles(theme => ({
  page: {
    // marginLeft: 'calc((100vw - 100%) * -1)',
    // paddingLeft: 'calc(100vw - 100%)',
    paddingBottom: theme.spacing(8)
  },
  header: {
    height: '280px',
    backgroundColor: '#0A1E2C'
  },
  paper: {
    width: '640px',
    margin: '-150px auto 30px auto',
    position: 'relative',
    maxWidth: '90vw',
    padding: '0 3%'
  },
  h1: {
    fontSize: 25,
    textAlign: 'center',
    padding: '5% 0 8%'
  },
  hide: {
    display: 'none'
  },
  explanation: {
    fontStyle: 'italic',
    textDecoration: 'underline'
  },
  note: {
    fontStyle: 'italic',
    fontSize: '0.7rem'
  },
  sectionHeading: {
    fontSize: 18,
    margin: `${theme.spacing(4)}px 0 ${theme.spacing(3)}px 40px`
  },
  p: {
    margin: `${theme.spacing(4)}px 0 ${theme.spacing(3)}px 40px`
  },
  questionWrapper: {
    paddingBottom: theme.spacing(3)
  },
  notNumberedQuestion: {
    paddingLeft: '36px'
  },
  texture: {
    backgroundImage: `url(${texture})`,
    backgroundSize: 'cover',
    opacity: 0.013,
    height: '100%'
  },
  table: {
    marginTop: theme.spacing(1),
    marginLeft: '32px',
    width: 'calc(100% - 32px)',
    '@media(max-width: 710px)': {
      marginLeft: 0,
      width: '100%'
    }
  },
  tableCellPadding: {
    padding: '2px 2px',
    '&:last-child': {
      paddingRight: '2px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2px 0',
      '&:last-child': {
        paddingRight: 0
      }
    }
  },
  positive: {
    color: '#4caf50;'
  },
  error: {
    color: '#A6192E'
  },
  negativeResult: {
    color: '#afafaf'
  },
  logo: {
    position: 'absolute',
    top: '24px',
    left: '24px',
    width: '240px'
  },
  inputsGridWrapper: {
    paddingLeft: '32px'
  },
  input: {
    marginLeft: '40px'
  },
  resultBtn: {
    color: theme.palette.secondary.main
  },
  buttonWrapper: {
    display: 'block',
    padding: '60px 0 50px 0',
    textAlign: 'center'
  },
  buttonReset: {
    marginLeft: theme.spacing(2.5),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1)
    }
  },
  resultPaper: {
    position: 'relative',
    marginTop: '0px',
    padding: '2% 3%'
  },
  resultHeading: {
    color: theme.palette.secondary.main,
    position: 'relative',
    textAlign: 'center',
    marginBottom: '10px'
  },
  generalResultRow: {
    fontSize: '15px',
    marginBottom: '6px',
    lineHeight: '20px',
    opacity: 0.9,
    [theme.breakpoints.down('xs')]: {
      marginBottom: '12px'
    }
  },
  generalResultRowNote: {
    opacity: 0.5,
    fontStyle: 'italic',
    fontSize: '0.75rem'
  },
  generalResultLabel: {
    opacity: 0.72
  },
  generalResultLabelIndented: {
    marginLeft: '18px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  row: {
    marginBottom: theme.spacing(2)
  },
  generalResult: {
    opacity: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  resultTable: {},
  generalHeadingWrapper: {
    position: 'relative',
    display: 'inline-block',
    margin: '16px 0 6px 0'
  },
  generalHeadingBottomBorder: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: '60%',
    backgroundColor: '00205B',
    height: '2px'
  },
  resetResult: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  gridCounter: {
    opacity: 0.72,
    position: 'absolute',
    top: '8px',
    [theme.breakpoints.down('xs')]: {
      left: -10
    }
  },
  grayResult: {
    color: '#afafaf'
  }
}));

export const QuestionnairePage = () => {
  const [state, setState] = useState(initialState);
  const [evaluation, setEvaluation] = useState(initialEvalState);
  const [isEvaluated, setIsEvaluated] = useState(false);
  const [errorQuestions, setErrorQuestions] = useState([]);
  const [showMoreDetails, setShowMoreDetails] = useState([]);

  const classes = useStyles();

  const isQn16disabled =
    state.questionsAndAnswers[15][1][1] &&
    state.questionsAndAnswers[15][2][1] &&
    state.questionsAndAnswers[15][3][1];

  useEffect(() => {
    let id = null;

    if (isEvaluated) {
      id = setTimeout(() => {
        const div = document.getElementById('startResult');
        div.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }, 100);
    }

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [isEvaluated, evaluation.general]);

  const applyQuestonRule = (question, questionNumber, answer) => {
    const temp = question;

    // Only 1 answer can be checked or any other than first one
    // if (questionNumber === 3 || questionNumber === 4) {
    //   temp.forEach((_, i) => {
    //     if (answer === 1) {
    //       if (i !== 1) {
    //         temp[i] = false;
    //       }
    //     } else {
    //       if (i === 1) {
    //         temp[i] = false;
    //       }
    //     }
    //   });
    // }

    return temp;
  };

  const toggleShowMore = index => e => {
    const temp = showMoreDetails.slice();
    temp[index] = !temp[index];
    setShowMoreDetails(temp);
  };

  const handleCheckboxChange = (question, answer) => event => {
    const questionsAndAnswers = state.questionsAndAnswers.slice();

    questionsAndAnswers[question][answer] = event.target.checked;
    questionsAndAnswers[question] = applyQuestonRule(questionsAndAnswers[question], question, answer);

    setState({ ...state, questionsAndAnswers });
  };

  const handleInputBlur = e => {
    const { name, value } = e.target;

    console.log(typeof value);
    if (name !== 'investmentPercentageFromAssets' && value < 1) {
      setState({ ...state, [name]: '' });
    } else if (name === 'investmentPercentageFromAssets') {
      if (value <= 0 || value > 100) {
        setState({ ...state, [name]: '' });
      }
    } else if (name === 'birthYear') {
      setState({
        ...state,
        birthYear: value,
        age: new Date().getFullYear() - Number(value)
      });
    }
  };

  const handleInputChange = useCallback(event => {
    const { name, value } = event.target;

    // Allow only one decimal place
    if (name === 'investmentPercentageFromAssets') {
      const regex = new RegExp(/^\d*\.?\d?$/g);
      const isValid = regex.exec(value) !== null && value <= 100;

      if (isValid) {
        setState(prevState => ({ ...prevState, [name]: value }));
      }
    } else {
      setState(prevState => ({ ...prevState, [name]: value }));
    }
  }, []);

  const handleRadioChange = (question, answer) => {
    const questionsAndAnswers = state.questionsAndAnswers.slice();

    const checkedIndex = questionsAndAnswers[question].findIndex(c => c === true);

    if (checkedIndex > -1) {
      questionsAndAnswers[question][checkedIndex] = false;
    }

    questionsAndAnswers[question][answer] = true;

    setState(prevState => ({ ...prevState, questionsAndAnswers }));
  };

  const handleTableRadioChange = (question, column, answer) => event => {
    const questionsAndAnswers = state.questionsAndAnswers.slice();

    const checkedIndex = questionsAndAnswers[question][column].findIndex(c => c === true);

    if (checkedIndex > -1) {
      questionsAndAnswers[question][column][checkedIndex] = false;
    }

    questionsAndAnswers[question][column][answer] = event.target.checked;

    setState({ ...state, questionsAndAnswers });
  };

  const resetErrorQuestions = () => {
    if (errorQuestions.length > 0) {
      errorQuestions.forEach(errQuestion => {
        errQuestion.classList.remove(classes.error);
      });

      setErrorQuestions([]);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = [];

    resetErrorQuestions();

    const addErr = index => {
      errors.push(index);

      if (isValid) {
        isValid = false;
      }
    };

    const validateQuestion = (q, index) => {
      const isAnswered = q.findIndex(a => a === true) > -1;

      if (!isAnswered) {
        addErr(index);
      }
    };

    state.questionsAndAnswers.forEach((q, index) => {
      if (!state.questionsAndAnswers[5][1] && (index === 6 || index === 7 || index === 8)) return;
      if (!state.questionsAndAnswers[5][2] && (index === 9 || index === 10 || index === 11)) return;
      if (!state.questionsAndAnswers[5][3] && (index === 12 || index === 13 || index === 14 || index === 30))
        return;

      if (isQn16disabled && index === 16) return;

      if (index === 0 || index === 21 || index === 22) return;

      if (index === 15 || index === 16) {
        state.questionsAndAnswers[index].forEach((tq, column) => {
          validateQuestion(tq, index);
        });
      } else {
        validateQuestion(q, index);
      }
    });

    if (state.birthYear === '') addErr(0);
    if (state.investment === '') addErr(21);
    if (state.investmentPercentageFromAssets === '') addErr(22);

    if (errors.length > 0) {
      let scrollQuestion = null;
      const errorQuestionsTemp = [];

      errors.forEach((qn, index) => {
        const question = document.getElementById('question' + qn);

        if (question) {
          question.classList.add(classes.error);

          if (index === 0) {
            scrollQuestion = question;
          }

          errorQuestionsTemp.push(question);
        }
      });

      if (scrollQuestion !== null) {
        scrollQuestion.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }

      setErrorQuestions(errorQuestionsTemp);
    }

    return isValid;
  };

  const handleSubmit = e => {
    e.preventDefault();

    /* if (true) { */
    if (validateForm()) {
      const generalStrat = new GeneralStrategy(state);
      const generalStratManager = new EvalStrategyManager(generalStrat);
      const generalStratEval = generalStratManager.evaluation;

      const bondStrat = new BondEvalStrategy(state, generalStratEval);
      const bondsStrategyManager = new EvalStrategyManager(bondStrat);

      const billOfEchangeStrat = new BillOfExchangeEvalStrategy(state, generalStratEval);
      const billsOfExchangeStrategyManager = new EvalStrategyManager(billOfEchangeStrat);

      const shareStrat = new ShareEvalStrategy(state, generalStratEval);
      const shareEvalStrategyManager = new EvalStrategyManager(shareStrat);

      const priorityShareStrat = new PriorityShareEvalStrategy(state, generalStratEval);
      const priorityShareEvalStrategyManager = new EvalStrategyManager(priorityShareStrat);

      const bondsEval = bondsStrategyManager.evaluation;
      const billOfExchangeEval = billsOfExchangeStrategyManager.evaluation;
      const sharesEval = shareEvalStrategyManager.evaluation;
      const priorityShareEval = priorityShareEvalStrategyManager.evaluation;

      setEvaluation({
        general: generalStratEval,
        bonds: bondsEval,
        billsOfEchange: billOfExchangeEval,
        shares: sharesEval,
        priorityShares: priorityShareEval
      });

      setIsEvaluated(true);
    } else {
      showErrorSnackbar('Nie sú vyplnené všetky otázky');
    }
  };

  const handleResetClick = e => {
    e.preventDefault();
    setState({
      ...initialState,
      questionsAndAnswers: getQuestionsAndAnswersInitState()
    });
    resetErrorQuestions();
    setEvaluation(initialEvalState);
    setIsEvaluated(false);

    const div = document.getElementById('startQuestionnaire');
    div.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const exportToCSV = () => {
    let csvContent = 'data:text/csv;charset=utf-8,';

    // Header
    csvContent += 'birthYear,age,investment,investmentPercentageFromAssets,';
    const headerQuestions = state.questionsAndAnswers
      .map((q, i) =>
        i !== 0
          ? Array.isArray(q[1])
            ? state.questionsAndAnswers[i]
                .map((_, ti) => 'q' + i + '.' + ti)
                .filter(q => q !== '')
                .join(',')
            : 'q' + i
          : ''
      )
      .filter(q => q !== '')
      .join(',');
    csvContent += headerQuestions + '\r\n';

    // Answers
    csvContent +=
      state.birthYear +
      ',' +
      state.age +
      ',' +
      state.investment +
      ',' +
      state.investmentPercentageFromAssets +
      ',';

    const answers = state.questionsAndAnswers
      .map((q, i) =>
        i !== 0
          ? Array.isArray(q[1])
            ? state.questionsAndAnswers[i]
                .map((tq, ti) =>
                  ti !== 0
                    ? tq.findIndex(ta => ta === true) > -1
                      ? tq.findIndex(ta => ta === true)
                      : ''
                    : 'remove'
                )
                .filter(q => q !== 'remove')
                .join(',')
            : q.reduce((str, q, i) => (q === true ? (str !== '' ? (str += '|' + i) : i) : str), '')
          : 'remove'
      )
      .filter(q => q !== 'remove')
      .join(',');

    csvContent += answers;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'ID_' + formatDateForExport(new Date()));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    showSnackbar('Dotazník bol vyexportovany');
  };

  const ImportProxy = () => {
    const input = document.getElementById('csv');
    if (input) {
      input.click();
    }
  };

  const ImportCSV = e => {
    const reader = new FileReader();

    reader.onload = () => {
      const lines = reader.result.split('\n');
      const header = lines[0].split(',');
      const answers = lines[1].split(',');
      const obj = { questionsAndAnswers: getQuestionsAndAnswersInitState() };
      header.forEach((h, i) => (obj[h] = answers[i]));

      header.forEach((h, i) => {
        if (obj[h] === 'age') {
          obj[h] = Number(answers[i]);
        } else {
          obj[h] = answers[i];
        }
      });

      // Process lines
      Object.keys(obj).forEach(key => {
        if (key.startsWith('q')) {
          if (key === 'questionsAndAnswers') return;

          // It is a question
          const number = key.substr(1).split('.');

          if (number.length > 1) {
            // Table question
            if (obj[key]) {
              obj.questionsAndAnswers[Number(number[0])][Number(number[1])][Number(obj[key])] = true;
            }
          } else {
            // Normal question
            const answers = obj[key].split('|');
            answers.forEach(answer => answer && (obj.questionsAndAnswers[Number(number)][Number(answer)] = true));
          }
          delete obj[key];
        }
      });
      setState(obj);
      resetErrorQuestions();
      setIsEvaluated(false);

      showSnackbar('Dotazník bol importovaný');
    };

    reader.readAsText(e.currentTarget.files[0]);
  };

  return (
    <div className={classes.page} style={{ paddingLeft: 'calc(100vw-100%)' }}>
      <ExportImportPopover>
        <ul style={{ marginBottom: '16px', listStyle: 'none' }}>
          <li style={{ padding: '4px 0' }}>
            <Button onClick={exportToCSV} variant="outlined" style={{ width: '100%' }}>
              Export data
            </Button>
          </li>
          <li style={{ padding: '4px 0' }}>
            <Button onClick={ImportProxy} variant="outlined" style={{ width: '100%' }}>
              Import data
            </Button>
          </li>
        </ul>
      </ExportImportPopover>
      <input id="csv" type="file" style={{ display: 'none' }} onChange={ImportCSV} />
      <header className={classes.header}>
        <div className={classes.texture} />
        <Logo className={classes.logo} />
      </header>
      <Paper id="startQuestionnaire" className={classes.paper}>
        <h1 className={classes.h1}>Investičný dotazník 2023</h1>
        <form onSubmit={handleSubmit}>
          <section style={{ paddingBottom: '16px' }}>
            <Question
              number={0}
              text="Uveďte rok narodenia:"
              wrapperClassName={cx(classes.questionWrapper, classes.notNumberedQuestion)}
              hideNumber={true}
            >
              <FormControl>
                <Input
                  name="birthYear"
                  id="birthYear"
                  type="number"
                  value={state.birthYear}
                  inputProps={{ min: 1, step: 1 }}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  aria-describedby="component-helper-text"
                />
              </FormControl>
            </Question>
            <Answer value={0} text="Neprofesionálny klient" disabled checkbox checked />
          </section>
          <section>
            <h4 className={classes.sectionHeading}>A. Informácie o znalostiach klienta</h4>
            <p className={classes.p}>
              Pozn. – pri niektorých otázkach v časti A. môže byť viacero správnych odpovedí.
            </p>
            <Question
              number={1}
              wrapperClassName={classes.questionWrapper}
              text="Aké je Vaše najvyššie dosiahnuté vzdelanie?"
            >
              <RadioGroup
                value={state.questionsAndAnswers[1].findIndex(a => a === true)}
                onChange={e => handleRadioChange(1, e.target.value)}
              >
                <Answer text="základné" value={1} />
                <Answer text="stredoškolské" value={2} />
                <Answer text="vysokoškolské" value={3} />
              </RadioGroup>
            </Question>
            <Question
              number={2}
              wrapperClassName={classes.questionWrapper}
              text="Pracujete alebo ste v období posledných 5 rokov pracovali v oblasti, ktorá súvisí s cennými papiermi?"
            >
              <RadioGroup
                value={state.questionsAndAnswers[2].findIndex(a => a === true)}
                onChange={e => handleRadioChange(2, e.target.value)}
              >
                <Answer text="áno" value={1} />
                <Answer text="nie" value={2} />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={3}
              text="Akým spôsobom získavate znalosti v oblasti investovania?"
            >
              <RadioGroup
                value={state.questionsAndAnswers[3].findIndex(a => a === true)}
                onChange={e => handleRadioChange(3, e.target.value)}
              >
                <Answer
                  value={1}
                  text="nezaujímam sa bližšie o investovanie, avšak viem, že táto oblasť existuje"
                />
                <Answer
                  value={2}
                  text="o investovanie sa zaujímam len občas, príležitostne (sledujem televízne noviny, internet) a viem, že s investovaním do cenných papierov je spojené riziko"
                />
                <Answer
                  value={3}
                  text="o investovanie sa zaujímam aktívne (sledujem finančné a ekonomické správy v TV a na internete, cielene si takéto informácie vyhľadávam na internete), poznám niektoré druhy cenných papierov (ich základnú charakteristiku a rozdiely medzi nimi) a viem, že s investovaním do cenných papierov je spojená rôzna úroveň rizika"
                />
              </RadioGroup>
            </Question>
            <Question wrapperClassName={classes.questionWrapper} number={'3A'} text="Čo je holdingová spoločnosť?">
              <RadioGroup
                value={state.questionsAndAnswers[29].findIndex(a => a === true)}
                onChange={e => handleRadioChange(29, e.target.value)}
              >
                <Answer
                  value={1}
                  text="kapitálová spoločnosť, ktorá vlastní akcie alebo podiely iných podnikov v takom množstve, aby to stačilo na ovládanie (kontrolu) činnosti týchto podnikov"
                />
                <Answer
                  value={2}
                  text="spoločnosť, ktorá je vlastnená a ovládaná minimálne 3 inými spoločnosťami"
                />
                <Answer
                  value={3}
                  text="spoločnosť, ktorá je vlastnená a ovládaná výhradne zahraničnými akcionármi"
                />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={4}
              text="Ktoré z nižšie uvedených investičných služieb poznáte?"
            >
              <Answer
                text="prijatie a postúpenie pokynu klienta týkajúceho sa jedného alebo viacerých finančných nástrojov (napr. nákup a / alebo predaj cenných papierov (finančných nástrojov))"
                checkbox={true}
                checked={state.questionsAndAnswers[4][1] || false}
                onChange={handleCheckboxChange(4, 1)}
                disabled={state.questionsAndAnswers[4][3]}
              />
              <Answer
                text="investičné poradenstvo"
                checkbox={true}
                checked={state.questionsAndAnswers[4][2] || false}
                onChange={handleCheckboxChange(4, 2)}
                disabled={state.questionsAndAnswers[4][3]}
              />
              <Answer
                text="nepoznám žiadne"
                checkbox={true}
                checked={state.questionsAndAnswers[4][3] || false}
                onChange={handleCheckboxChange(4, 3)}
                disabled={state.questionsAndAnswers[4][2] || state.questionsAndAnswers[4][1]}
              />
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={5}
              text="Ktoré z týchto produktov finančného trhu poznáte (poznáte ich charakteristiku, základné princípy fungovania a riziká s nimi spojené)?"
            >
              <Answer
                text="dlhopisy"
                checkbox={true}
                checked={state.questionsAndAnswers[5][1] || false}
                onChange={handleCheckboxChange(5, 1)}
                disabled={state.questionsAndAnswers[5][4]}
              />
              <Answer
                text="zmenky"
                checkbox={true}
                checked={state.questionsAndAnswers[5][2] || false}
                onChange={handleCheckboxChange(5, 2)}
                disabled={state.questionsAndAnswers[5][4]}
              />
              <Answer
                text="akcie"
                checkbox={true}
                checked={state.questionsAndAnswers[5][3] || false}
                onChange={handleCheckboxChange(5, 3)}
                disabled={state.questionsAndAnswers[5][4]}
              />
              <Answer
                text="nepoznám žiadne"
                checkbox={true}
                checked={state.questionsAndAnswers[5][4] || false}
                onChange={handleCheckboxChange(5, 4)}
                disabled={
                  state.questionsAndAnswers[5][3] ||
                  state.questionsAndAnswers[5][2] ||
                  state.questionsAndAnswers[5][1]
                }
              />
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={6}
              disabled={!state.questionsAndAnswers[5][1]}
              text="Čo rozumiete pod pojmom dlhopis?"
            >
              <RadioGroup
                value={state.questionsAndAnswers[6].findIndex(a => a === true)}
                onChange={e => handleRadioChange(6, e.target.value)}
              >
                <Answer text="majetková účasť" value={1} disabled={!state.questionsAndAnswers[5][1]} />
                <Answer text="cenný papier" value={2} disabled={!state.questionsAndAnswers[5][1]} />
                <Answer
                  text="potvrdenie o vklade prostriedkov"
                  value={3}
                  disabled={!state.questionsAndAnswers[5][1]}
                />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={7}
              text="Práva a povinnosti emitenta a majiteľa dlhopisu pri konkrétnej emisii dlhopisov sú uvedené v"
              disabled={!state.questionsAndAnswers[5][1]}
            >
              <RadioGroup
                value={state.questionsAndAnswers[7].findIndex(a => a === true)}
                onChange={e => handleRadioChange(7, e.target.value)}
              >
                <Answer
                  text="poznámkach k účtovnej závierke emitenta"
                  value={1}
                  disabled={!state.questionsAndAnswers[5][1]}
                />
                <Answer text="zákone o dlhopisoch" value={2} disabled={!state.questionsAndAnswers[5][1]} />
                <Answer text="emisných podmienkach" value={3} disabled={!state.questionsAndAnswers[5][1]} />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={8}
              text="Kupón dlhopisu je"
              disabled={!state.questionsAndAnswers[5][1]}
            >
              <RadioGroup
                value={state.questionsAndAnswers[8].findIndex(a => a === true)}
                onChange={e => handleRadioChange(8, e.target.value)}
                disabled={!state.questionsAndAnswers[5][1]}
              >
                <Answer
                  text="zisk emitenta z predaja dlhopisov"
                  value={1}
                  disabled={!state.questionsAndAnswers[5][1]}
                />
                <Answer text="deň splatnosti dlhopisu" value={2} disabled={!state.questionsAndAnswers[5][1]} />
                <Answer text="výnos z dlhopisu" value={3} disabled={!state.questionsAndAnswers[5][1]} />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={9}
              text="Zmenka"
              disabled={!state.questionsAndAnswers[5][2]}
            >
              <RadioGroup
                value={state.questionsAndAnswers[9].findIndex(a => a === true)}
                onChange={e => handleRadioChange(9, e.target.value)}
              >
                <Answer
                  text="oprávňuje jej majiteľa hlasovať na valnom zhromaždení emitenta"
                  value={1}
                  disabled={!state.questionsAndAnswers[5][2]}
                />
                <Answer
                  text="je cenný papier, z ktorého majiteľovi vyplýva právo na to, aby mu bola vyplatená suma uvedená na zmenke"
                  value={2}
                  disabled={!state.questionsAndAnswers[5][2]}
                />
                <Answer
                  text="nie je cenný papier, je to majetkový podiel na spoločnosti"
                  value={3}
                  disabled={!state.questionsAndAnswers[5][2]}
                />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={10}
              text="Zmenka môže byť v podobe"
              disabled={!state.questionsAndAnswers[5][2]}
            >
              <RadioGroup
                value={state.questionsAndAnswers[10].findIndex(a => a === true)}
                onChange={e => handleRadioChange(10, e.target.value)}
              >
                <Answer text="zaknihovanej" value={1} disabled={!state.questionsAndAnswers[5][2]} />
                <Answer text="listinnej" value={2} disabled={!state.questionsAndAnswers[5][2]} />
                <Answer text="telefonickej" value={3} disabled={!state.questionsAndAnswers[5][2]} />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={11}
              text="Po kúpe zmenky"
              disabled={!state.questionsAndAnswers[5][2]}
            >
              <RadioGroup
                value={state.questionsAndAnswers[11].findIndex(a => a === true)}
                onChange={e => handleRadioChange(11, e.target.value)}
              >
                <Answer
                  text="musím si zmenku uložiť do notárskej úschovy"
                  value={1}
                  disabled={!state.questionsAndAnswers[5][2]}
                />
                <Answer
                  text="musím si zmenku uložiť do obchodného registra"
                  value={2}
                  disabled={!state.questionsAndAnswers[5][2]}
                />
                <Answer
                  text="môžem si zmenku uložiť u obchodníka s cennými papiermi"
                  value={3}
                  disabled={!state.questionsAndAnswers[5][2]}
                />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={12}
              text="Akcia predstavuje"
              disabled={!state.questionsAndAnswers[5][3]}
            >
              <RadioGroup
                value={state.questionsAndAnswers[12].findIndex(a => a === true)}
                onChange={e => handleRadioChange(12, e.target.value)}
              >
                <Answer
                  text="nárok na zľavy z tovarov a služieb akciovej spoločnosti"
                  value={1}
                  disabled={!state.questionsAndAnswers[5][3]}
                />
                <Answer
                  text="práva akcionára poberať výnosy z podielových listov"
                  value={2}
                  disabled={!state.questionsAndAnswers[5][3]}
                />
                <Answer
                  text="spoluvlastnícky podiel akcionára na akciovej spoločnosti s právom na výplatu dividendy"
                  value={3}
                  disabled={!state.questionsAndAnswers[5][3]}
                />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={'12A'}
              wider={true}
              text="Prioritné akcie sú"
              disabled={!state.questionsAndAnswers[5][3]}
            >
              <Answer
                text="akcie, s ktorými sú spojené prednostné práva týkajúce sa výplaty dividend, ak o ich vyplatení rozhodne valné zhromaždenie"
                checkbox={true}
                checked={state.questionsAndAnswers[30][1] || false}
                onChange={handleCheckboxChange(30, 1)}
                disabled={
                  !state.questionsAndAnswers[5][3] ||
                  (state.questionsAndAnswers[30].filter(o => o === true).length === 2 &&
                    !state.questionsAndAnswers[30][1])
                }
              />
              <Answer
                text="akcie, s ktorými je spojené právo hlasovania na valnom zhromaždení"
                checkbox={true}
                checked={state.questionsAndAnswers[30][2] || false}
                onChange={handleCheckboxChange(30, 2)}
                disabled={
                  !state.questionsAndAnswers[5][3] ||
                  (state.questionsAndAnswers[30].filter(o => o === true).length === 2 &&
                    !state.questionsAndAnswers[30][2])
                }
              />
              <Answer
                text="akcie, s ktorými nie je spojené právo hlasovania na valnom zhromaždení (ich majitelia majú všetky ostatné práva spojené s akciami)"
                checkbox={true}
                checked={state.questionsAndAnswers[30][3] || false}
                onChange={handleCheckboxChange(30, 3)}
                disabled={
                  !state.questionsAndAnswers[5][3] ||
                  (state.questionsAndAnswers[30].filter(o => o === true).length === 2 &&
                    !state.questionsAndAnswers[30][3])
                }
              />
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={13}
              text="Akcia môže byť vydaná:"
              disabled={!state.questionsAndAnswers[5][3]}
            >
              <RadioGroup
                value={state.questionsAndAnswers[13].findIndex(a => a === true)}
                onChange={e => handleRadioChange(13, e.target.value)}
              >
                <Answer
                  text="len v listinnej a v žiadnej inej podobe"
                  value={1}
                  disabled={!state.questionsAndAnswers[5][3]}
                />
                <Answer
                  text="len v zaknihovanej a v žiadnej inej podobe"
                  value={2}
                  disabled={!state.questionsAndAnswers[5][3]}
                />
                <Answer
                  text="v listinnej alebo zaknihovanej podobe"
                  value={3}
                  disabled={!state.questionsAndAnswers[5][3]}
                />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={14}
              text="Dividenda je:"
              disabled={!state.questionsAndAnswers[5][3]}
            >
              <RadioGroup
                value={state.questionsAndAnswers[14].findIndex(a => a === true)}
                onChange={e => handleRadioChange(14, e.target.value)}
              >
                <Answer
                  text="oprávnenie na riadenie akciovej spoločnosti"
                  value={1}
                  disabled={!state.questionsAndAnswers[5][3]}
                />
                <Answer
                  text="výnos z akcie vyplatený akcionárovi zo zisku spoločnosti, ak tak rozhodne valné zhromaždenie"
                  value={2}
                  disabled={!state.questionsAndAnswers[5][3]}
                />
                <Answer
                  text="predkupné právo na 50%-ný podiel v spoločnosti"
                  value={3}
                  disabled={!state.questionsAndAnswers[5][3]}
                />
              </RadioGroup>
            </Question>
          </section>
          <section>
            <h4 className={classes.sectionHeading}>B. Informácie o skúsenostiach klienta</h4>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={15}
              text="Vykonávali ste v posledných 5 rokoch transakcie s niektorým z cenných papierov uvedených v tabuľke?"
            >
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.tableCellPadding}
                      style={{ fontWeight: 800, width: '60%' }}
                      title="Vyznačte jednu z uvedených možností a) až d) pre každý cenný papier"
                    >
                      Počet obchodov / Cenný papier *
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} style={{ fontWeight: 800 }} align="center">
                      Dlhopisy
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} style={{ fontWeight: 800 }} align="center">
                      Zmenky
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} style={{ fontWeight: 800 }} align="center">
                      Akcie
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={1}>
                    <TableCell className={classes.tableCellPadding} component="th" scope="row">
                      nikdy som neobchodoval
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        onChange={handleTableRadioChange(15, 1, 1)}
                        checked={state.questionsAndAnswers[15][1][1] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        onChange={handleTableRadioChange(15, 2, 1)}
                        checked={state.questionsAndAnswers[15][2][1] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        onChange={handleTableRadioChange(15, 3, 1)}
                        checked={state.questionsAndAnswers[15][3][1] || false}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell className={classes.tableCellPadding} component="th" scope="row">
                      realizoval som 1 až 2 obchody
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        onChange={handleTableRadioChange(15, 1, 2)}
                        checked={state.questionsAndAnswers[15][1][2] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        onChange={handleTableRadioChange(15, 2, 2)}
                        checked={state.questionsAndAnswers[15][2][2] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        onChange={handleTableRadioChange(15, 3, 2)}
                        checked={state.questionsAndAnswers[15][3][2] || false}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell className={classes.tableCellPadding} component="th" scope="row">
                      realizoval som 3 až 4 obchodov
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        onChange={handleTableRadioChange(15, 1, 3)}
                        checked={state.questionsAndAnswers[15][1][3] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        onChange={handleTableRadioChange(15, 2, 3)}
                        checked={state.questionsAndAnswers[15][2][3] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        onChange={handleTableRadioChange(15, 3, 3)}
                        checked={state.questionsAndAnswers[15][3][3] || false}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell className={classes.tableCellPadding} component="th" scope="row">
                      realizoval som 5 a viac obchodov
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        onChange={handleTableRadioChange(15, 1, 4)}
                        checked={state.questionsAndAnswers[15][1][4] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        onChange={handleTableRadioChange(15, 2, 4)}
                        checked={state.questionsAndAnswers[15][2][4] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        onChange={handleTableRadioChange(15, 3, 4)}
                        checked={state.questionsAndAnswers[15][3][4] || false}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={16}
              disabled={isQn16disabled}
              text="V akom dlhom období ste za posledných 5 rokov vykonávali obchody (nákup/predaj) s cennými papiermi?"
            >
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.tableCellPadding}
                      style={{ fontWeight: 800, width: '60%' }}
                      title="Vyznačte jednu z uvedených možností a) až d) pre každý cenný papier"
                    >
                      Obchodovanie / Cenný papier *
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} style={{ fontWeight: 800 }} align="center">
                      Dlhopisy
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} style={{ fontWeight: 800 }} align="center">
                      Zmenky
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} style={{ fontWeight: 800 }} align="center">
                      Akcie
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={1}>
                    <TableCell className={classes.tableCellPadding} component="th" scope="row">
                      nikdy som neobchodoval
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        disabled={isQn16disabled}
                        onChange={handleTableRadioChange(16, 1, 1)}
                        checked={state.questionsAndAnswers[16][1][1] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        disabled={isQn16disabled}
                        onChange={handleTableRadioChange(16, 2, 1)}
                        checked={state.questionsAndAnswers[16][2][1] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        disabled={isQn16disabled}
                        onChange={handleTableRadioChange(16, 3, 1)}
                        checked={state.questionsAndAnswers[16][3][1] || false}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell className={classes.tableCellPadding} component="th" scope="row">
                      do 1 roka
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        disabled={isQn16disabled || state.questionsAndAnswers[15][1][1]}
                        onChange={handleTableRadioChange(16, 1, 2)}
                        checked={state.questionsAndAnswers[16][1][2] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        disabled={isQn16disabled || state.questionsAndAnswers[15][2][1]}
                        onChange={handleTableRadioChange(16, 2, 2)}
                        checked={state.questionsAndAnswers[16][2][2] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        disabled={isQn16disabled || state.questionsAndAnswers[15][3][1]}
                        onChange={handleTableRadioChange(16, 3, 2)}
                        checked={state.questionsAndAnswers[16][3][2] || false}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell className={classes.tableCellPadding} component="th" scope="row">
                      od 1 do 3 rokov
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        disabled={isQn16disabled || state.questionsAndAnswers[15][1][1]}
                        onChange={handleTableRadioChange(16, 1, 3)}
                        checked={state.questionsAndAnswers[16][1][3] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        disabled={isQn16disabled || state.questionsAndAnswers[15][2][1]}
                        onChange={handleTableRadioChange(16, 2, 3)}
                        checked={state.questionsAndAnswers[16][2][3] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        disabled={isQn16disabled || state.questionsAndAnswers[15][3][1]}
                        onChange={handleTableRadioChange(16, 3, 3)}
                        checked={state.questionsAndAnswers[16][3][3] || false}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell className={classes.tableCellPadding} component="th" scope="row">
                      viac ako 3 rokov
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        disabled={isQn16disabled || state.questionsAndAnswers[15][1][1]}
                        onChange={handleTableRadioChange(16, 1, 4)}
                        checked={state.questionsAndAnswers[16][1][4] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        disabled={isQn16disabled || state.questionsAndAnswers[15][2][1]}
                        onChange={handleTableRadioChange(16, 2, 4)}
                        checked={state.questionsAndAnswers[16][2][4] || false}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCellPadding} align="center">
                      <Radio
                        color="primary"
                        disabled={isQn16disabled || state.questionsAndAnswers[15][3][1]}
                        onChange={handleTableRadioChange(16, 3, 4)}
                        checked={state.questionsAndAnswers[16][3][4] || false}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Question>
          </section>
          <section>
            <h4 className={classes.sectionHeading}>C. Informácie o finančnej situácii klienta</h4>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={17}
              text="Aký je zdroj pravidelných príjmov Vašej domácnosti?"
            >
              <Answer
                text="príjem zo zamestnania (mesačná mzda)"
                checkbox={true}
                checked={state.questionsAndAnswers[17][1] || false}
                onChange={handleCheckboxChange(17, 1)}
              />
              <Answer
                text="príjem z podnikateľskej činnosti"
                checkbox={true}
                checked={state.questionsAndAnswers[17][2] || false}
                onChange={handleCheckboxChange(17, 2)}
              />
              <Answer
                text="dôchodok"
                checkbox={true}
                checked={state.questionsAndAnswers[17][3] || false}
                onChange={handleCheckboxChange(17, 3)}
              />
              <Answer
                text="iný zdroj príjmu (uveďte aký):"
                checkbox={true}
                checked={state.questionsAndAnswers[17][4] || false}
                onChange={handleCheckboxChange(17, 4)}
              >
                <Input style={{ marginLeft: '12px' }} inputProps={{ style: { padding: 0 } }} />
              </Answer>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={18}
              text="Aká je výška čistých príjmov Vašej domácnosti?"
            >
              <RadioGroup
                value={state.questionsAndAnswers[18].findIndex(a => a === true)}
                onChange={e => handleRadioChange(18, e.target.value)}
              >
                <Answer text="do 15 000 eur ročne" value={1} />
                <Answer text="od 15 000 eur do 25 000 eur ročne" value={2} />
                <Answer text="viac ako 25 000 eur ročne" value={3} />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={19}
              text="Koľko % z Vášho pravidelného čistého mesačného príjmu tvoria pravidelné finančné záväzky Vašej domácnosti (napr. SIPO, nájomné, spotrebný úver, hypotéka, lízing, poistenia, výživné)?"
            >
              <RadioGroup
                value={state.questionsAndAnswers[19].findIndex(a => a === true)}
                onChange={e => handleRadioChange(19, e.target.value)}
              >
                <Answer text="menej ako 30%" value={1} />
                <Answer text="od 30% do 50%" value={2} />
                <Answer text="50% a viac" value={3} />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={20}
              text={
                <>
                  V akej výške je v súčasnosti celkový objem aktív Vašej domácnosti (hotovosť, vklady v bankách,{' '}
                  <span
                    title="V prípade, ak je Vaša nehnuteľnosť viazaná záväzkovo-právnym vzťahom (napr. hypotékou), predstavuje hodnota nehnuteľnosti sumu, ktorá je znížená o zostatkovú (nesplatenú) hodnotu úveru (hypotéky). Príklad: trhová hodnota nehnuteľnosti 200 000 € mínus zostatková hodnota úveru 50 000 € = výsledná hodnota nehnuteľnosti 150 000 €. Suma 150 000 € predstavuje hodnotu, ktorú je možné započítať do Vášho celkového objemu aktív."
                    className={classes.explanation}
                  >
                    nehnuteľnosti
                  </span>
                  , investície, hnuteľný majetok)?
                </>
              }
            >
              <RadioGroup
                value={state.questionsAndAnswers[20].findIndex(a => a === true)}
                onChange={e => handleRadioChange(20, e.target.value)}
              >
                <Answer text="do 200 000 eur" value={1} />
                <Answer text="od 200 000 eur do 500 000 eur" value={2} />
                <Answer text="od 500 000 eur do 750 000 eur" value={3} />
                <Answer text="od 750 000 eur do 1 000 000 eur" value={4} />
                <Answer text="nad 1000 000 eur" value={5} />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={cx(classes.questionWrapper)}
              number={21}
              text="Akú časť z celkového objemu aktív Vašej domácnosti predstavuje voľná hotovosť, ktorú chcete investovať?"
            >
              <FormControl>
                <Input
                  name="investment"
                  id="investment"
                  type="number"
                  className={classes.input}
                  value={state.investment}
                  inputProps={{ min: 1 }}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  endAdornment={<InputAdornment position="end">€</InputAdornment>}
                  aria-describedby="investment-amount-help-text"
                />
              </FormControl>
            </Question>
            <Question
              wrapperClassName={cx(classes.questionWrapper)}
              number={22}
              text="Moja plánovaná investícia predstavuje z celkového objemu aktív mojej domácnosti:"
            >
              <FormControl>
                <Input
                  name="investmentPercentageFromAssets"
                  id="investmentPercentageFromAssets"
                  type="number"
                  className={classes.input}
                  value={state.investmentPercentageFromAssets}
                  inputProps={{ step: 1, pattern: 'd+.?d?(?!d)' }}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  aria-describedby="investment-amount-help-text"
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                />
              </FormControl>
            </Question>
          </section>
          <section>
            <h4 className={classes.sectionHeading}>
              D. Informácie o prístupe klienta k rizikám a jeho investičných cieľoch
            </h4>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={23}
              text="Ste ochotný zniesť riziko spojené s investíciou?"
            >
              <RadioGroup
                value={state.questionsAndAnswers[23].findIndex(a => a === true)}
                onChange={e => handleRadioChange(23, e.target.value)}
              >
                <Answer
                  text="neakceptujem žiadne riziko, chcem mať istotu, že sa mi vrátia všetky moje investované prostriedky a nie som pripravený zniesť žiadnu stratu"
                  value={1}
                />
                <Answer
                  text="som si vedomý, že pri investovaní existuje aj riziko celkovej straty investovaných prostriedkov, avšak som ochotný zniesť maximálne 20 %-nú stratu"
                  value={2}
                />
                <Answer
                  text="som si vedomý, že pri investovaní existuje aj riziko celkovej straty investovaných prostriedkov, avšak som ochotný zniesť maximálne stratu do 50 %"
                  value={3}
                />
                <Answer
                  text="som si vedomý, že pri investovaní existuje aj riziko celkovej straty investovaných prostriedkov a som ochotný zniesť aj celkovú stratu"
                  value={4}
                />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={24}
              text="Prípadná celková strata investovaných prostriedkov pri zohľadnení pravidelných príjmov a  pravidelných záväzkov Vašej domácnosti by pre Vás znamenala:"
            >
              <RadioGroup
                value={state.questionsAndAnswers[24].findIndex(a => a === true)}
                onChange={e => handleRadioChange(24, e.target.value)}
              >
                <Answer text="žiadnu zmenu životného štandardu" value={1} />
                <Answer text="nevýraznú zmenu životného štandardu" value={2} />
                <Answer text="výraznú zmenu životného štandardu" value={3} />
                <Answer text="existenčné problémy" value={4} />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={25}
              text={
                <>
                  Aký je Váš investičný horizont (doba, počas ktorej plánujete držať nakúpené cenné papiere)?{' '}
                  <span className={classes.note}>Pozn. - Označte max 3 možnosti</span>
                </>
              }
            >
              <Answer
                text="do 2 rokov"
                checked={state.questionsAndAnswers[25][1] || false}
                checkbox
                onChange={handleCheckboxChange(25, 1)}
              />
              <Answer
                text="od 2 rokov do 7 rokov"
                checked={state.questionsAndAnswers[25][2] || false}
                checkbox
                onChange={handleCheckboxChange(25, 2)}
              />
              <Answer
                text="viac ako 7 rokov"
                checked={state.questionsAndAnswers[25][3] || false}
                checkbox
                onChange={handleCheckboxChange(25, 3)}
              />
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={26}
              text="Od Vašej investície do cenných papierov očakávate výnos"
            >
              <RadioGroup
                value={state.questionsAndAnswers[26].findIndex(a => a === true)}
                onChange={e => handleRadioChange(26, e.target.value)}
              >
                <Answer text="aspoň na úrovni 1 ročného termínovaného vkladu" value={1} />
                <Answer text="vyšší výnos, avšak nie nadpriemerný" value={2} />
                <Answer text="nadpriemerný v porovnaní s výnosom uvedeným v bode a) a b)" value={3} />
              </RadioGroup>
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={27}
              text={
                <>
                  Cieľom mojej investície je: <span className={classes.note}>Pozn. - Označte max 2 možnosti</span>
                </>
              }
            >
              <Answer
                text="doplnenie môjho súčasného portfólia finančných produktov"
                checked={state.questionsAndAnswers[27][1] || false}
                checkbox
                onChange={handleCheckboxChange(27, 1)}
                disabled={
                  state.questionsAndAnswers[27].filter(o => o === true).length === 2 &&
                  !state.questionsAndAnswers[27][1]
                }
              />
              <Answer
                text="stať sa akcionárom spoločnosti"
                checked={state.questionsAndAnswers[27][2] || false}
                checkbox
                onChange={handleCheckboxChange(27, 2)}
                disabled={
                  state.questionsAndAnswers[27].filter(o => o === true).length === 2 &&
                  !state.questionsAndAnswers[27][2]
                }
              />
              <Answer
                text="ochrana pred infláciou"
                checked={state.questionsAndAnswers[27][3] || false}
                checkbox
                onChange={handleCheckboxChange(27, 3)}
                disabled={
                  state.questionsAndAnswers[27].filter(o => o === true).length === 2 &&
                  !state.questionsAndAnswers[27][3]
                }
              />
              <Answer
                text="vytvorenie finančnej rezervy do budúcnosti"
                checked={state.questionsAndAnswers[27][4] || false}
                onChange={handleCheckboxChange(27, 4)}
                checkbox
                disabled={
                  state.questionsAndAnswers[27].filter(o => o === true).length === 2 &&
                  !state.questionsAndAnswers[27][4]
                }
              />
            </Question>
            <Question
              wrapperClassName={classes.questionWrapper}
              number={28}
              text={
                <>
                  Preferujete, aby Vaša investícia do cenných papierov bola{' '}
                  <span
                    title="„udržateľná investícia“ je investícia do hospodárskej činnosti, ktorá prispieva k environmentálnemu cieľu meraná napríklad prostredníctvom kľúčových ukazovateľov účinnosti využívania energie, energie z obnoviteľných zdrojov, surovín, vody a pôdy, ukazovateľov produkcie odpadu a emisií skleníkových plynov a ukazovateľov vplyvu na biodiverzitu a ukazovateľov obehového hospodárstva, alebo investícia do hospodárskej činnosti, ktorá prispieva k sociálnemu cieľu, najmä investícia, ktorá prispieva k riešeniu nerovnosti, alebo investícia, ktorá podporuje sociálnu súdržnosť, sociálne začlenenie a pracovnoprávne vzťahy, alebo investícia do ľudského kapitálu, či do ekonomicky alebo sociálne znevýhodnených komunít a za predpokladu, že sa takýmito investíciami výrazne nenaruší žiaden z uvedených cieľov, a spoločnosti, do ktorých sa investovalo, dodržiavajú postupy dobrej správy, najmä pokiaľ ide o zdravé štruktúry riadenia, zamestnanecké vzťahy, odmeňovanie zamestnancov a plnenie daňových povinností"
                    className={classes.explanation}
                  >
                    udržateľnou investíciou
                  </span>
                  , t. j. investíciou do hospodárskej činnosti, ktorá prispieva k environmentálnemu cieľu?
                </>
              }
            >
              <RadioGroup
                value={state.questionsAndAnswers[28].findIndex(a => a === true)}
                onChange={e => handleRadioChange(28, e.target.value)}
              >
                <Answer text="áno" value={1} />
                <Answer text="nie" value={2} />
              </RadioGroup>
            </Question>
          </section>
          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={cx(classes.resultBtn, classes.button)}
            >
              vyhodnotiť
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              type="submit"
              className={classes.buttonReset}
              onClick={handleResetClick}
            >
              Resetovať dotazník
            </Button>
          </div>
        </form>
      </Paper>
      {isEvaluated && (
        <Paper id="startResult" className={cx(classes.paper, classes.resultPaper)}>
          <div
            style={{
              height: '250px',
              backgroundColor: '#0A1E2C',
              position: 'absolute',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              left: 0,
              right: 0,
              top: 0
            }}
          >
            <div className={classes.texture} />
          </div>
          <section>
            <Typography variant="h5" component="h5" className={classes.resultHeading}>
              VYHODNOTENIE
            </Typography>
            <div
              className={classes.row}
              style={{ background: 'white', position: 'relative', borderRadius: '8px' }}
            >
              <Table size="medium" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 800 }}>A. Informácie o znalostiach klienta</TableCell>
                    <TableCell style={{ fontWeight: 800 }} align="center">
                      Dlhopisy
                    </TableCell>
                    <TableCell style={{ fontWeight: 800 }} align="center">
                      Zmenky
                    </TableCell>
                    <TableCell style={{ fontWeight: 800 }} align="center">
                      Akcie
                    </TableCell>
                    <TableCell style={{ fontWeight: 800 }} align="center">
                      Prioritné akcie
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={1}>
                    <TableCell component="th" scope="row">
                      a) Žiadne
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.bonds.find(i => i.id.startsWith('knowledge')).result === QUESTION_RESULT.NONE
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.billsOfEchange.find(i => i.id.startsWith('knowledge')).result ===
                      QUESTION_RESULT.NONE
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.shares.find(i => i.id.startsWith('knowledge')).result === QUESTION_RESULT.NONE
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.priorityShares.find(i => i.id.startsWith('knowledge')).result ===
                      QUESTION_RESULT.NONE
                        ? 'X'
                        : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell component="th" scope="row">
                      b) Základné
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.bonds.find(i => i.id.startsWith('knowledge')).result === QUESTION_RESULT.BASIC
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.billsOfEchange.find(i => i.id.startsWith('knowledge')).result ===
                      QUESTION_RESULT.BASIC
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.shares.find(i => i.id.startsWith('knowledge')).result === QUESTION_RESULT.BASIC
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.priorityShares.find(i => i.id.startsWith('knowledge')).result ===
                      QUESTION_RESULT.BASIC
                        ? 'X'
                        : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell component="th" scope="row">
                      c) Stredné
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.bonds.find(i => i.id.startsWith('knowledge')).result === QUESTION_RESULT.ADVANCED
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.billsOfEchange.find(i => i.id.startsWith('knowledge')).result ===
                      QUESTION_RESULT.ADVANCED
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.shares.find(i => i.id.startsWith('knowledge')).result ===
                      QUESTION_RESULT.ADVANCED
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.priorityShares.find(i => i.id.startsWith('knowledge')).result ===
                      QUESTION_RESULT.ADVANCED
                        ? 'X'
                        : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell component="th" scope="row">
                      d) Rozsiahle
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.bonds.find(i => i.id.startsWith('knowledge')).result === QUESTION_RESULT.EXPERT
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.billsOfEchange.find(i => i.id.startsWith('knowledge')).result ===
                      QUESTION_RESULT.EXPERT
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.shares.find(i => i.id.startsWith('knowledge')).result === QUESTION_RESULT.EXPERT
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.priorityShares.find(i => i.id.startsWith('knowledge')).result ===
                      QUESTION_RESULT.EXPERT
                        ? 'X'
                        : ''}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div className={classes.row}>
              <Table size="medium" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 800 }}>B. Informácie o skúsenostiach klienta</TableCell>
                    <TableCell style={{ fontWeight: 800 }} align="center">
                      Dlhopisy
                    </TableCell>
                    <TableCell style={{ fontWeight: 800 }} align="center">
                      Zmenky
                    </TableCell>
                    <TableCell style={{ fontWeight: 800 }} align="center">
                      Akcie
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={1}>
                    <TableCell component="th" scope="row">
                      a) Žiadne
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.bonds.find(i => i.id.startsWith('experience')).result === QUESTION_RESULT.NONE
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.billsOfEchange.find(i => i.id.startsWith('experience')).result ===
                      QUESTION_RESULT.NONE
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.shares.find(i => i.id.startsWith('experience')).result === QUESTION_RESULT.NONE
                        ? 'X'
                        : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell component="th" scope="row">
                      b) Základné
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.bonds.find(i => i.id.startsWith('experience')).result === QUESTION_RESULT.BASIC
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.billsOfEchange.find(i => i.id.startsWith('experience')).result ===
                      QUESTION_RESULT.BASIC
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.shares.find(i => i.id.startsWith('experience')).result === QUESTION_RESULT.BASIC
                        ? 'X'
                        : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell component="th" scope="row">
                      c) Stredné
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.bonds.find(i => i.id.startsWith('experience')).result ===
                      QUESTION_RESULT.ADVANCED
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.billsOfEchange.find(i => i.id.startsWith('experience')).result ===
                      QUESTION_RESULT.ADVANCED
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.shares.find(i => i.id.startsWith('experience')).result ===
                      QUESTION_RESULT.ADVANCED
                        ? 'X'
                        : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell component="th" scope="row">
                      d) Rozsiahle
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.bonds.find(i => i.id.startsWith('experience')).result === QUESTION_RESULT.EXPERT
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.billsOfEchange.find(i => i.id.startsWith('experience')).result ===
                      QUESTION_RESULT.EXPERT
                        ? 'X'
                        : ''}
                    </TableCell>
                    <TableCell align="center">
                      {evaluation.shares.find(i => i.id.startsWith('experience')).result === QUESTION_RESULT.EXPERT
                        ? 'X'
                        : ''}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div className={classes.row}>
              <Table size="medium" aria-label="a dense table">
                <TableHead>
                  <TableRow key={0}>
                    <TableCell style={{ fontWeight: 800 }}>C. Informácie o finančnej situácii klienta</TableCell>
                    <TableCell style={{ fontWeight: 800, width: '20%' }}> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={1}>
                    <TableCell component="th" scope="row">
                      {evaluation.general.find(g => g.id === 'FinancialBackground').result}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell component="th" scope="row">
                      Plánovaná výška investície (v eur)
                    </TableCell>
                    <TableCell align="center">{state.investment} Eur</TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell component="th" scope="row">
                      Podiel plánovej investície (v %)
                    </TableCell>
                    <TableCell align="center">{state.investmentPercentageFromAssets} %</TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell component="th" scope="row" className={classes.grayResult}>
                      Teoretický objem aktív podľa podielu investície
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        typeof evaluation.general.find(g => g.id === 'TeoreticalVolumeOfAssets').result !==
                        'number'
                          ? classes.error
                          : classes.grayResult
                      }
                    >
                      {evaluation.general.find(g => g.id === 'TeoreticalVolumeOfAssets').result + ''}
                    </TableCell>
                  </TableRow>
                  <TableRow key={5}>
                    <TableCell component="th" scope="row">
                      Maximálna výška investície
                      <br />
                      {evaluation.general.find(g => g.id === 'MaxInvestment').result > 100000 &&
                        typeof evaluation.general.find(g => g.id === 'TeoreticalVolumeOfAssets').result ===
                          'number' && (
                          <Typography
                            component="span"
                            variant="subtitle2"
                            gutterBottom={true}
                            style={{ fontStyle: 'italic', color: '#00205B' }}
                          >
                            <br />
                            Pozor, nevyhnutné vyplniť formulár AML (hodn. maxim. invest. nad 100k EUR)
                          </Typography>
                        )}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {evaluation.general.find(g => g.id === 'MaxInvestment').result}
                    </TableCell>
                  </TableRow>
                  <TableRow key={6}>
                    <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                      Klient {evaluation.general.find(g => g.id === 'RiskTakeAbility').result ? '' : 'nie '}je
                      schopný zniesť riziko celkovej straty
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div className={classes.row}>
              <Table size="medium" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 800 }}>D. Informácie o prístupe klienta k rizikám</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={1}>
                    <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                      Klient {evaluation.general.find(g => g.id === 'RiskWillingness').result ? '' : 'nie '}je
                      ochotný zniesť riziko celkovej straty
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell component="th" scope="row">
                      Investičný horizont klienta je
                      <br />
                      <Typography
                        component="span"
                        variant="subtitle2"
                        gutterBottom={true}
                        style={{ fontStyle: 'italic', color: '#00205B' }}
                      >
                        <br />
                        Pozn. Vhodný CP, ktorého zostatková doba do splatnosti je{' '}
                        {state.questionsAndAnswers[25]
                          .map((q, idx) =>
                            q
                              ? idx === 1
                                ? 'do 2 rokov'
                                : idx === 2
                                ? 'nad 2 do 7 rokov'
                                : 'viac ako 7 rokov'
                              : null
                          )
                          .filter((_, i) => i !== 0)
                          .join(', ')}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', verticalAlign: 'top' }}>
                      {evaluation.general.find(g => g.id === 'InvestmentHorizon').result.join(', ')}
                    </TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell component="th" scope="row">
                      Klient preferuje udržateľnú investíciu
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {evaluation.general.find(g => g.id === 'InvestmentExpectation').result}
                    </TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell component="th" scope="row">
                      Rizikový profil klienta pre dlhopisy
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {evaluation.bonds.find(g => g.id === 'Risk').result} <br />
                    </TableCell>
                  </TableRow>
                  <TableRow key={5}>
                    <TableCell component="th" scope="row">
                      Rizikový profil klienta pre zmenky
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {evaluation.billsOfEchange.find(g => g.id === 'Risk').result}
                    </TableCell>
                  </TableRow>
                  <TableRow key={6}>
                    <TableCell component="th" scope="row">
                      Rizikový profil klienta pre akcie
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {evaluation.shares.find(g => g.id === 'Risk').result} <br />
                    </TableCell>
                  </TableRow>
                  <TableRow key={7}>
                    <TableCell component="th" scope="row">
                      Vek klienta je
                    </TableCell>
                    <TableCell align="center">{state.age}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div className={classes.generalHeadingWrapper}>
              <h5 style={{ margin: '8px 0' }}>
                Cieľový trh pre dlhopisy{' '}
                <IconButton onClick={toggleShowMore(0)}>
                  {!showMoreDetails[0] ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </h5>
              <div className={classes.generalHeadingBottomBorder} />
            </div>
            {evaluation.bonds
              .filter(s => s.id.startsWith('positive'))
              .map(e => (
                <div
                  key={e.id}
                  className={cx(classes.generalResultRow, {
                    [classes.generalResultRowNote]: e.id !== 'positiveTotal',
                    [classes.hide]: e.id !== 'positiveTotal' && !showMoreDetails[0]
                  })}
                >
                  <span>{e.label}: </span>
                  <span
                    className={cx(classes.generalResult, {
                      [classes.positive]: e.id === 'positiveTotal' && e.result
                    })}
                  >
                    {e.result ? 'pozitívny' : 'negatívny'}
                  </span>
                </div>
              ))}
            <div className={classes.generalHeadingWrapper}>
              <h5 style={{ margin: '8px 0' }}>
                Cieľový trh pre zmenky{' '}
                <IconButton onClick={toggleShowMore(1)}>
                  {!showMoreDetails[1] ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </h5>
              <div className={classes.generalHeadingBottomBorder} />
            </div>
            {evaluation.billsOfEchange
              .filter(s => s.id.startsWith('positive'))
              .map(e => (
                <div
                  key={e.id}
                  className={cx(classes.generalResultRow, {
                    [classes.generalResultRowNote]: e.id !== 'positiveTotal',
                    [classes.hide]: e.id !== 'positiveTotal' && !showMoreDetails[1]
                  })}
                >
                  <span>{e.label}: </span>
                  <span
                    className={cx(classes.generalResult, {
                      [classes.positive]: e.id === 'positiveTotal' && e.result
                    })}
                  >
                    {e.result ? 'pozitívny' : 'negatívny'}
                  </span>
                </div>
              ))}
            <div className={classes.generalHeadingWrapper}>
              <h5 style={{ margin: '8px 0' }}>
                Cieľový trh pre akcie{' '}
                <IconButton onClick={toggleShowMore(2)}>
                  {!showMoreDetails[2] ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </h5>
              <div className={classes.generalHeadingBottomBorder} />
            </div>
            {evaluation.shares
              .filter(s => s.id.startsWith('positive'))
              .map(e => (
                <div
                  key={e.id}
                  className={cx(classes.generalResultRow, {
                    [classes.generalResultRowNote]: e.id !== 'positiveTotal',
                    [classes.hide]: e.id !== 'positiveTotal' && !showMoreDetails[2]
                  })}
                >
                  <span>{e.label}: </span>
                  <span
                    className={cx(classes.generalResult, {
                      [classes.positive]: e.id === 'positiveTotal' && e.result
                    })}
                  >
                    {e.result ? 'pozitívny' : 'negatívny'}
                  </span>
                </div>
              ))}
            <div className={classes.generalHeadingWrapper}>
              <h5 style={{ margin: '8px 0' }}>
                Cieľový trh pre prioritné akcie{' '}
                <IconButton onClick={toggleShowMore(2)}>
                  {!showMoreDetails[2] ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </h5>
              <div className={classes.generalHeadingBottomBorder} />
            </div>
            {evaluation.priorityShares
              .filter(s => s.id.startsWith('positive'))
              .map(e => (
                <div
                  key={e.id}
                  className={cx(classes.generalResultRow, {
                    [classes.generalResultRowNote]: e.id !== 'positiveTotal',
                    [classes.hide]: e.id !== 'positiveTotal' && !showMoreDetails[2]
                  })}
                >
                  <span>{e.label}: </span>
                  <span
                    className={cx(classes.generalResult, {
                      [classes.positive]: e.id === 'positiveTotal' && e.result
                    })}
                  >
                    {e.result ? 'pozitívny' : 'negatívny'}
                  </span>
                </div>
              ))}
          </section>
          <div className={cx(classes.buttonWrapper, classes.resetResult)}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              type="submit"
              className={classes.buttonReset}
              onClick={handleResetClick}
            >
              Resetovať dotazník
            </Button>
          </div>
        </Paper>
      )}
    </div>
  );
};
