export class GeneralStrategy {
  constructor(state, bondsEval, billOfExchangeEval, sharesEval) {
    this._answers = state.questionsAndAnswers;
    this._age = state.age;
    this._investment = state.investment;
    this._investmentPercentage = Number(state.investmentPercentageFromAssets);
  }

  calculateInvestmentLimit(teoreticalVolumeOfAssets) {
    const investmentPercentage = this._investmentPercentage > 40 ? 40 : this._investmentPercentage;
    const answers = this._answers;

    const basis = teoreticalVolumeOfAssets * (investmentPercentage / 100);
    let result = basis;

    if (answers[24][2] && !answers[17][3]) {
      result = basis * 0.9;
    } else if (answers[24][1] && answers[17][3]) {
      result = basis * 0.75;
    } else if (answers[24][2] && answers[17][3]) {
      result = basis * 0.7;
    }

    return Math.floor(result);
  }

  calculateInvestmentExpectation() {
    return this._answers[28][1];
  }

  calculateInvestmentHorizon() {
    const answers = this._answers;
    const age = this._age;

    let result = [];

    if (answers[25][1] && age <= 86) {
      result.push('Krátkodobý');
    }

    if (answers[25][2] && age <= 86) {
      result.push('Strednodobý');
    }

    if (answers[25][3] && age <= 76) {
      result.push('Dlhodobý');
    }

    if (result.length === 0) {
      result[0] = 'presiahnutý vek alebo nevhodná kombinácia veku a invesčného horizontu (ot.25)';
    }

    return result;
  }

  calculateTeoreticalVolumeOfAssets() {
    const answers = this._answers;
    const amount = (this._investment / this._investmentPercentage) * 100;

    console.log(amount);
    let result = amount;

    if (
      (answers[20][1] && amount > 200000) ||
      (answers[20][2] && (amount < 200000 || amount > 500000)) ||
      (answers[20][3] && (amount < 500000 || amount > 750000)) ||
      (answers[20][4] && (amount < 750000 || amount > 1000000)) ||
      (answers[20][5] && amount < 1000000)
    ) {
      result = Math.round(amount) + ' EUR - Nesprávne uvedený objem aktív alebo investície - ot. 20, 21, 22';
    }

    return result;
  }

  calculateFinancialBackground() {
    const answers = this._answers;

    let result = 'Nedostatočné finančné zázemie';

    if (
      (answers[18][2] &&
        answers[19][1] &&
        (answers[20][2] || answers[20][3] || answers[20][4] || answers[20][5])) ||
      (answers[18][2] && answers[19][2] && (answers[20][3] || answers[20][4] || answers[20][5])) ||
      (answers[18][3] && answers[19][1] && (answers[20][2] || answers[20][3])) ||
      (answers[18][3] && answers[19][2] && answers[20][3])
    ) {
      result = 'Dostatočné finančné zázemie';
    } else if (
      (answers[18][3] && answers[19][1] && (answers[20][4] || answers[20][5])) ||
      (answers[18][3] && answers[19][2] && (answers[20][4] || answers[20][5]))
    ) {
      result = 'Nadštandardné finančné zázemie';
    }

    return result;
  }

  evaluate() {
    const teoreticalVolumeOfAssets = this.calculateTeoreticalVolumeOfAssets();
    const financialBackground = this.calculateFinancialBackground();

    return [
      {
        id: 'FinancialBackground',
        result: financialBackground
      },
      {
        id: 'RiskTakeAbility',
        result:
          (this._answers[24][1] || this._answers[24][2]) && financialBackground !== 'Nedostatočné finančné zázemie'
      },
      {
        id: 'RiskWillingness',
        result: this._answers[23][4]
      },
      {
        id: 'TeoreticalVolumeOfAssets',
        result:
          typeof teoreticalVolumeOfAssets === 'number'
            ? Math.round(teoreticalVolumeOfAssets)
            : teoreticalVolumeOfAssets
      },
      {
        id: 'MaxInvestment',
        result:
          !this._answers[23][1] || !this._answers[24][3] || !this._answers[24][4]
            ? typeof teoreticalVolumeOfAssets === 'number'
              ? this.calculateInvestmentLimit(teoreticalVolumeOfAssets) + ' Eur'
              : 'Nesprávne uvedený objem aktív alebo investície - ot. 20, 21, 22'
            : this._answers[23][1]
            ? '0 Eur (Dôvod: Neakceptuje riziko - Odpoveď 23a)'
            : '0 Eur (Dôvod: Ohrozený živ. štandard - Odpoveď 24c/d)'
      },
      {
        id: 'InvestmentLimitRetirement',
        label: 'Vek',
        result: this._answers[16][3]
      },

      {
        id: 'InvestmentHorizon',
        label: 'Investičný horizont klienta je',
        result: this.calculateInvestmentHorizon()
      },
      {
        id: 'InvestmentExpectation',
        result: this.calculateInvestmentExpectation() ? 'Áno' : 'Nie'
      }
    ];
  }
}
