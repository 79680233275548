import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import './index.scss';
import App from './app/App';
import { AppStateContextProvider, initialAppState } from './_libs/contexts/AppStateContext';
import * as serviceWorker from './_libs/serviceWorker';
import swConfig from './_libs/swConfig';

const theme = createMuiTheme({
  typography: {
    fontFamily: ['HelveticaNeueLTPro', 'sans-serif'].join(','),
    button: {
      fontFamily: ['Arial', 'HelveticaNeueLTPro', 'sans-serif'].join(',')
    }
  },
  palette: {
    text: {},
    primary: {
      main: '#0A1E2C'
    },
    secondary: {
      main: '#EEC7A1'
    }
  }
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <AppStateContextProvider initialState={initialAppState}>
      <App />
    </AppStateContextProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

serviceWorker.register(swConfig);
