import { isNTrue, isMinNTrue } from '../helpers';
import { QUESTION_RESULT } from '../../_constants/commonConstants';

export class BondEvalStrategy {
  constructor(state, generalStrategy) {
    this._answers = state.questionsAndAnswers;
    this._age = state.age;
    this._generalStrategy = generalStrategy;
  }

  calculateKnowledge() {
    const answers = this._answers;
    let result = QUESTION_RESULT.NONE;

    if (
      (answers[3][1] && answers[5][4]) ||
      (answers[3][1] && answers[5][1] && isMinNTrue([answers[6][2], answers[7][3], answers[8][3]], 1))
    ) {
      result = QUESTION_RESULT.BASIC;
    } else if (
      answers[3][2] &&
      (answers[4][1] || answers[4][2]) &&
      answers[5][1] &&
      isMinNTrue([answers[6][2], answers[7][3], answers[8][3]], 2)
    ) {
      result = QUESTION_RESULT.ADVANCED;
    } else if (
      (answers[1][2] || answers[1][3]) &&
      answers[3][3] &&
      (answers[4][1] || answers[4][2]) &&
      answers[5][1] &&
      isNTrue([answers[6][2], answers[7][3], answers[8][3]], 3)
    ) {
      result = QUESTION_RESULT.EXPERT;
    }

    return result;
  }

  calculateExperience() {
    const answers = this._answers;
    let result = QUESTION_RESULT.NONE;

    if (
      (answers[15][1][2] && (answers[16][1][2] || answers[16][1][2] || answers[16][1][2])) ||
      (answers[16][1][2] && (answers[15][1][2] || answers[15][1][2] || answers[15][1][2]))
    ) {
      result = QUESTION_RESULT.BASIC;
    } else if (
      (answers[15][1][3] && (answers[16][1][3] || answers[16][1][4])) ||
      ((answers[15][1][3] || answers[15][1][4]) && answers[16][1][3])
    ) {
      result = QUESTION_RESULT.ADVANCED;
    } else if (answers[15][1][4] && answers[16][1][4]) {
      result = QUESTION_RESULT.EXPERT;
    }

    return result;
  }

  calculateRisk(knowledgeResult, experienceResult) {
    const answers = this._answers;
    let result = 'nemožné vyhodnotiť profil';

    const financialBackground = this._generalStrategy.find(g => g.id === 'FinancialBackground').result;

    if (knowledgeResult === QUESTION_RESULT.NONE || experienceResult === QUESTION_RESULT.NONE) {
      result = 'neinvestičný profil';
    } else if (
      ((knowledgeResult === QUESTION_RESULT.BASIC && experienceResult !== QUESTION_RESULT.NONE) ||
        (knowledgeResult !== QUESTION_RESULT.NONE && experienceResult === QUESTION_RESULT.BASIC)) &&
      answers[25][1] &&
      answers[26][2] &&
      (answers[23][1] || answers[23][2])
    ) {
      result = 'konzervatívny';
    } else if (
      ((knowledgeResult === QUESTION_RESULT.ADVANCED &&
        experienceResult !== QUESTION_RESULT.NONE &&
        experienceResult !== QUESTION_RESULT.BASIC) ||
        (knowledgeResult !== QUESTION_RESULT.NONE &&
          knowledgeResult !== QUESTION_RESULT.BASIC &&
          experienceResult === QUESTION_RESULT.ADVANCED)) &&
      answers[25][2] &&
      answers[26][2] &&
      answers[23][3]
    ) {
      result = 'vyvážený';
    } else if (
      knowledgeResult === QUESTION_RESULT.EXPERT &&
      experienceResult === QUESTION_RESULT.EXPERT &&
      answers[23][4] &&
      answers[26][3] &&
      (financialBackground === 'Nadštandardné finančné zázemie' ||
        financialBackground === 'Dostatočné finančné zázemie')
    ) {
      result = 'dynamický';
    }

    return result;
  }

  calculatePositiveBy2(knowledge, experience) {
    return knowledge === QUESTION_RESULT.EXPERT && experience === QUESTION_RESULT.EXPERT;
  }

  calculatePositiveby3(riskResult) {
    return riskResult === 'dynamický';
  }

  calculatePositiveBy4() {
    return this._generalStrategy.find(g => g.id === 'RiskWillingness');
  }

  calculatePositiveBy5() {
    return this._generalStrategy.find(g => g.id === 'RiskTakeAbility');
  }

  calculatePositiveBy6() {
    const finBackground = this._generalStrategy.find(g => g.id === 'FinancialBackground').result;

    return finBackground === 'Dostatočné finančné zázemie' || finBackground === 'Nadštandardné finančné zázemie';
  }

  calculatePositiveBy7() {
    return this._generalStrategy.find(g => g.id === 'InvestmentHorizon').result.some(r => r === 'Strednodobý');
  }

  calculatePositiveBy8() {
    return this._answers[26][3];
  }

  calculatePositiveBy9() {
    return this._answers[27][1] || this._answers[27][3] || this._answers[27][4];
  }
  evaluate() {
    const knowledgeResult = this.calculateKnowledge();
    const experienceResult = this.calculateExperience();
    const riskResult = this.calculateRisk(knowledgeResult, experienceResult);

    const isPositiveBy2 = this.calculatePositiveBy2(knowledgeResult, experienceResult);
    const isPositiveBy3 = this.calculatePositiveby3(riskResult);
    const isPositiveBy4 = this.calculatePositiveBy4();
    const isPositiveBy5 = this.calculatePositiveBy5();
    const isPositiveBy6 = this.calculatePositiveBy6();
    const isPositiveBy7 = this.calculatePositiveBy7();
    const isPositiveBy8 = this.calculatePositiveBy8();
    const isPositiveBy9 = this.calculatePositiveBy9();
    const isPositiveBy10 = this._answers[28][2];

    const isTotallyPositive =
      isPositiveBy2 &&
      isPositiveBy3 &&
      isPositiveBy4 &&
      isPositiveBy5 &&
      isPositiveBy6 &&
      isPositiveBy7 &&
      isPositiveBy8 &&
      isPositiveBy9 &&
      isPositiveBy10;

    return [
      {
        id: 'knowledgeBonds',
        label: 'A) Informácie o znalostiach klienta (dlhopisy)',
        result: knowledgeResult
      },
      {
        id: 'experienceBonds',
        label: 'B) Informácie o skúsenostiach klienta (dlhopisy)',
        result: experienceResult
      },
      {
        id: 'Risk',
        result: riskResult
      },
      {
        id: 'positiveTotal',
        label: 'Klient je pre cieľový trh dlhopisy',
        result: isTotallyPositive
      },
      {
        id: 'positive1',
        label: '1) Retailový (neprofesionálny) klient.',
        result: true
      },
      {
        id: 'positive2',
        label: '2) Klient, ktorý je skusený s rozsiahlou urovňou znalosti aj skusenosti.',
        result: isPositiveBy2
      },
      {
        id: 'positive3',
        label: '3) Klient, ktorého rizikový profil je „dynamický klient“ pre DLHOPISY.',
        result: isPositiveBy3
      },
      {
        id: 'positive4',
        label: '4) Klient, ktorý je ochotný zniesť riziko celkovej straty investovaných prostriedkov.',
        result: isPositiveBy4
      },
      {
        id: 'positive5',
        label: '5) Klient, ktorý je schopný zniesť riziko celkovej straty investovaných prostriedkov.',
        result: isPositiveBy5
      },
      {
        id: 'positive6',
        label: '6) Klient, ktorý má dostatočné alebo nadštandardné fin. zázemie.',
        result: isPositiveBy6
      },
      {
        id: 'positive7',
        label: '7) Klient, ktorého invest. horiz je od 2r do 7r, zároveň ku dňu inv. neprevýšil 86r.',
        result: isPositiveBy7
      },
      {
        id: 'positive8',
        label: '8) Klient, ktorý od investície očakáva nadpriemerný výnos',
        result: isPositiveBy8
      },
      {
        id: 'positive9',
        label:
          '9) Klient, ktorého cieľom investície je doplnenie portfólia, ochrana pred infláciou, vytvorenie fin. rezervy.',
        result: isPositiveBy9
      },
      {
        id: 'positive10',
        label: '10) Klient, ktorého 28=B',
        result: isPositiveBy10
      }
    ];
  }
}
