import React, { Fragment } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import QuestionnairePage from '../questionnaire';
import LoginPage from '../login';
import { useAppState } from '../_libs/contexts/AppStateContext';

import './App.scss';

const useStyles = makeStyles({
  btn: {
    visibility: 'hidden',
    position: 'fixed',
    top: '10px',
    left: '10px',
    zIndex: 1
  }
});

function App() {
  const [{ isAuthenticated }] = useAppState();
  const classes = useStyles();

  return (
    <Fragment>
      <Button id="skip-waiting-sw-button" variant="contained" color="secondary" className={classes.btn}>
        Získaj najnovšiu verziu dotazníku
      </Button>
      {isAuthenticated ? <QuestionnairePage /> : <LoginPage />}
    </Fragment>
  );
}

export default App;
