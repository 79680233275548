export class EvalStrategyManager {
  constructor(strategy) {
    this._strategy = strategy;
  }

  set strategy(strategy) {
    this._strategy = strategy;
  }

  get strategy() {
    return this._strategy;
  }

  get evaluation() {
    return this._strategy.evaluate();
  }
}
