export default {
  // When new service worker is installed and ready to be activated, we want to show user a button to activate new service worker and if he gets newest version we skip waiting and active new service worker and reload the page
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      const button = document.getElementById("skip-waiting-sw-button");
      button.style.visibility = "visible";

      button.addEventListener("click", e => {
        waitingServiceWorker.addEventListener("statechange", event => {
          if (event.target.state === "activated") {
            window.location.reload();
          }
        });

        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      });
    }
  }
};
