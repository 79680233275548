export const isNTrue = (arr, necessaryTrueCount) => {
  const trueStatementsCount = arr.reduce((acc, curr) => (curr ? acc + 1 : acc), 0);

  return trueStatementsCount === necessaryTrueCount;
};

export const isMinNTrue = (arr, necessaryTrueCount) => {
  const trueStatementsCount = arr.reduce((acc, curr) => (curr ? acc + 1 : acc), 0);

  return trueStatementsCount >= necessaryTrueCount;
};
