import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import BtnIcon from "@material-ui/icons/KeyboardArrowRightSharp";
import { makeStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";

import { ReactComponent as Logo } from "../_assets/images/logo-cele.svg";

import {
  PW,
  IS_AUTHENTICATED_LOCAL_STORAGE_KEY
} from "../_constants/commonConstants";
import { useAppState } from "../_libs/contexts/AppStateContext";

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  textfieldWrapper: {
    position: "relative"
  },
  formWrap: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }
  },
  err: {
    fontFamily: "HelveticaNeueLTPro-Condensed",
    color: "#f44336",
    position: "absolute",
    bottom: "-14px",
    left: 0,
    fontSize: "0.8rem",
    width: "100%"
  },
  submitBtn: {
    minWidth: "32px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1) / 2
  },
  form: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "inline-flex"
    }
  },
  textField: {
    marginRight: theme.spacing(1)
  },
  logo: {
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      marginBottom: "36px"
    }
  }
}));

export const LoginPage = () => {
  const classes = useStyles();
  const [state, setAppState] = useAppState();
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handlePasswordChange = e => {
    const { value } = e.target;
    setPassword(value);

    if (error !== "") {
      setError("");
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (password === PW) {
      setAppState({ ...state, isAuthenticated: true });
      localStorage.setItem(IS_AUTHENTICATED_LOCAL_STORAGE_KEY, "true");
    } else {
      setError("Nesprávne heslo");
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.formWrap}>
        <Logo className={classes.logo} />
        <form onSubmit={handleSubmit} className={classes.form}>
          <input
            type="text"
            name="email"
            autocomplete="username email"
            style={{ display: "none" }}
          />
          <div className={classes.textfieldWrapper}>
            <TextField
              error={!!error}
              id="outlined-basic"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="new-password"
              className={classes.textField}
              label="Heslo"
              margin="dense"
              variant="outlined"
            />
            {error && <span className={classes.err}>{error}</span>}
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            className={classes.submitBtn}
          >
            <BtnIcon />
          </Button>
        </form>
      </div>
    </div>
  );
};
