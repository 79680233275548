/* eslint-disable */
import Snackbar from "node-snackbar";
import cx from "class-names";

import "node-snackbar/dist/snackbar.min.css";

/**
 *
 * @param {SnackbarOptions} options
 */
export const showSnackbar = (text, options) =>
  Snackbar.show({
    text,
    duration: 4000,
    actionText: "zrušiť",
    pos: "bottom-left",
    customClass: "snackbar-container",
    ...options
  });

export const showErrorSnackbar = (text, options) => {
  Snackbar.show({
    text,
    duration: 4000,
    actionText: "zrušiť",
    pos: "bottom-left",
    customClass: cx("snackbar-container", "snackbar-error"),
    ...options
  });
};
