import React, { useEffect } from 'react';
import { Fab, Popper } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Fade from '@material-ui/core/Fade';

export default function ExportImportPopover({ children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (anchorEl) {
      document.addEventListener('mousedown', handleClose, true);
    }

    return () => {
      if (anchorEl) {
        document.removeEventListener('mousedown', handleClose, true);
      }
    };
  }, [anchorEl]);

  const handleToggleClick = event => {
    setAnchorEl(!anchorEl ? event.currentTarget : null);
  };

  const handleClose = e => {
    if (!document.getElementById('exportImportPopperBtn').contains(e.target)) {
      setAnchorEl(null);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={{ position: 'fixed', bottom: '32px', right: '32px', zIndex: 5 }}>
      <Fab
        id="exportImportPopperBtn"
        variant="extended"
        style={{ backgroundColor: '#EEC7A1', color: '#0A1E2C' }}
        aria-describedby={id}
        onClick={handleToggleClick}
      >
        <ImportExportIcon style={{ marginRight: '12px' }} />
        Export / Import
      </Fab>
      <Popper id={id} open={open} placement="top-end" anchorEl={anchorEl} disablePortal={true} transition={true}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            {children}
          </Fade>
        )}
      </Popper>
    </div>
  );
}
