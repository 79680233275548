import React, { useState, createContext, useContext } from 'react';
import { IS_AUTHENTICATED_LOCAL_STORAGE_KEY } from '../../_constants/commonConstants';

const AppStateContext = createContext();

export const initialAppState = {
  isAuthenticated: localStorage.getItem(IS_AUTHENTICATED_LOCAL_STORAGE_KEY) === 'true',
};

export const AppStateContextProvider = ({ initialState, children }) => {
  const [appState, setAppState] = useState(initialState);

  return (
    <AppStateContext.Provider value={[appState, setAppState]}>{children}</AppStateContext.Provider>
  );
};

export const useAppState = () => useContext(AppStateContext);
