export const formatDateForExport = date => {
  const mm = date.getMonth() + 1; // getMonth() is zero-based
  const dd = date.getDate();
  const hh = date.getHours();
  const mins = date.getMinutes();

  return [
    (dd > 9 ? '' : '0') + dd,
    (mm > 9 ? '' : '0') + mm,
    date.getFullYear(),
    '_',
    (hh > 9 ? '' : '0') + hh,
    (mins > 9 ? '' : '0') + mins
  ].join('');
};
